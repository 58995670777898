<template>
  <div>
      <div class='appHeadLine'>Active Users ({{tenantName}}@{{node?node.shortname:''}})</div>
      <VueTableDynamic @loadAll="true" @cell-contextmenu="checkTwice"  @cell-click='check' :params="users"></VueTableDynamic>
      
      <button class='button' @click="getUsers()">Refresh</button>
      <button class='button' @click="pingEach()">Ping all</button>
      <button class='button' @click="logoutEach()">Logout all</button>
      <input class='button' v-model='send' @keyup="broadcast()"/>

      <ContextMenu ref="menu">
      <template slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="$refs.menu.close(); ping(contextData)">
          Ping this session
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData" @clicked="$refs.menu.close(); pingAll(contextData)">
          Ping {{ contextData.username }}
        </ContextMenuItem>
        <ContextMenuItem  @clicked="$refs.menu.close(); pingEach(contextData)">
          Ping all
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="$refs.menu.close(); logout(contextData)">
          Logout this session
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData" @clicked="$refs.menu.close(); logoutAll(contextData)">
          Logout {{ contextData.username }}
        </ContextMenuItem>
      </template>
    </ContextMenu>


  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js/dist/popper.min.js";
import "jquery/dist/jquery.min.js";
import {HTTP, tickerAPI, serviceAPI, myWSID, print, showError, setWSPayloadListener} from '@/variables.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import VueTableDynamic from '@/ext/VueTableDynamic';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBG, getFG } from '@/AppStyle.js';
var momentTZ = require('moment-timezone');
export default {
  name: "usersLoggedIn",
  components : {
    ContextMenu, ContextMenuItem,ContextMenuSep,VueTableDynamic
  },
  data() {
      return {
         users: { orig: [], data: [],
            header: 'row',
            height: 600,
            pagination: true,
            pageSize: 20,
            enableSearch: true,
            sort: [0, 1, 2, 3, 4, 5, 6, 7,8,9,10,11],
            pageSizes: [5, 10, 15, 20, 25, 50, 100],
            rowHeight: 18,
            dummy: {},
            headerBgColor: getBG(),
    		headerFgColor: getFG(),
            columnWidth: [
                {column: 0, width: '8%'}, 
                {column: 1, width: '8%'},
                {column: 2, width: '8%'},  
                {column: 7, width: '8%'}
                ],
            border: true },
        send: "",
        node: {},
        tenantName: {},
        wsIdCell: 0,
        getBG, getFG,
      }
  },
  methods: {
      checkTwice(event, rowIndex) // , columnIndex, data) 
      { 
          if ( rowIndex > 0 )
          {
            var usr = this.users.data[rowIndex];
            var user = this.users.orig.find(p => p.wsID === usr[this.wsIdCell]);
            this.$refs.menu.open(event, user)
           }
      },
      check(rowIndex, columnIndex, data) { 
          if ( rowIndex > 0 )
          {
            var usr = this.users.data[rowIndex];
            //alert( data +"-->" +JSON.stringify(usr))
            if ( data == 'Ping' )
            {
                
                this.ping( this.users.orig.find(p => p.wsID === usr[this.wsIdCell]));
            }
            if ( data == 'Logout' )
            {
                
                this.logout( this.users.orig.find(p => p.wsID === usr[this.wsIdCell]));
            }
          }
      },
      getUsers() {
          //console.log(tickerAPI+"/protected/all/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId);
          //alert(tickerAPI+"/protected/all/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId)
          HTTP.get( tickerAPI+"/protected/all/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId)
                    .then( response => {
                     // alert("----> "+JSON.stringify( response.data));
                        this.users.data = [];
                        this.users.orig = response.data;
                        var count = 0;
                        var header = [];
                        for(var row in response.data)
                        {
                            
                            if ( header.length == 0 )
                            {
                                for(var cell in response.data[row])
                                {
                                    if ( cell == 'wsID')
                                    {
                                        this.wsIdCell = count;
                                    }
                                    if ( !cell.endsWith("Id"))
                                    {
                                        header.push(cell);
                                        count++;
                                    }
                                    
                                    //console.log("----> "+row[cell]);
                                }
                                
                                this.users.data.push(header);
                            }
							
                           
                            if ( myWSID == response.data[row][header[this.wsIdCell]] )
                            {
                               //cells.push("");
                              //  this.users.highlight = { row: [ parseInt(row)+1 ] };
                            }
                            var cells = new Array()
                            for( cell in header)
                            {
                                var x = response.data[row][header[cell]];
                                if ( x )
                                {
                                    if (header[cell] == 'time')
                                    {
                                        var date = new Date(parseInt(x));
                                        var y = momentTZ(date).tz(sessionStorage.timezone).format(sessionStorage.timeFormat);
                                        cells.push(y);
                                    }
                                    else
                                    {    
                                        cells.push(x);
                                    }
                                } 
                                else 
                                { 
                                    cells.push("");
                                }
                                //console.log("----> "+row[cell]);
                            }
                            
                            this.users.data.push(cells);
                        }
                    }).catch(e => {
                        showError( this.$toast, "get users ", e)
                });
        return this.users;

      },
      getData(reply)
      {
          var u = this.users.data.find(p => p[this.wsIdCell] === reply.fromId);
          //alert(JSON.stringify(u));
          if ( reply.page.length > 50 )
          {
              reply.page =  reply.page.substring(0,40)+"...";
          }
          u[8] = reply.page;
          u[9] = reply.expires+"s";
          u[10] = reply.noOfRefresh;
          u[11] = (new Date().getTime() - reply.requestTime)+"ms";
          u[5] = reply.server;
          
          //alert(JSON.stringify(u));
          this.users.data.push( u );
          this.users.data.pop();
      },
      
      ping(user)
      {
    	this.$refs.menu.close(); 
        console.log("PING ----> "+JSON.stringify( user));
        print("sending ping");
        setWSPayloadListener(this.getData);
        HTTP.post( tickerAPI+"/protected/ping/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+user.wsID+"/"+myWSID)
                    .then( response => {
                    this.dummy = response;
                    print("ping sent to "+user.username+" - waiting for reply");
                    //alert("Ping send to " + user.wsID) 
                    }).catch(e => {
                        console.log("ERR----> "+JSON.stringify( e));
                });
      },
      pingAll(user)
      {
    	this.$refs.menu.close();   
        console.log("PING ----> "+JSON.stringify( user));
        for ( var usr in this.users.data )
        {
            if ( usr > 0 ) this.users.data[usr][9]="-";
        }
        setWSPayloadListener(this.getData);
        HTTP.post( tickerAPI+"/protected/pingAll/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+user.userId+"/"+myWSID)
                    .then( response => {
                    this.dummy = response;
                    print("ping sent to "+user.username+" - waiting for reply");
                    //alert("Ping send to " + user.wsID) 
                    }).catch(e => {
                        console.log("ERR----> "+JSON.stringify( e));
                });
      },
      pingEach()
      {
        for ( var usr in this.users.data )
        {
            if ( usr > 0 ) this.users.data[usr][9]="-";
        }
        setWSPayloadListener(this.getData);
        HTTP.post( tickerAPI+"/protected/pingEach/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myWSID)
                    .then( response => {
                       this.dummy = response;
                       print("ping sent to all - waiting for reply");
                    //alert("Ping send to " + user.wsID) 
                    }).catch(e => {
                        console.log("ERR----> "+JSON.stringify( e));
                });
      },
      broadcast()
      {
        
        var obj = {"info": this.send };
        console.log("broadcast ----> "+JSON.stringify(obj));
        HTTP.post( tickerAPI+"/protected/broadcast/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.tenantId, obj)
                    .then( response => {
                        this.dummy = response;
                        console.log("----> "+JSON.stringify( response.data));
                   
                    }).catch(e => {
                        console.log("ERR----> "+JSON.stringify( e));
                });
      },
      logout(user)
      {
    	  let that = this;
        //console.log("logout ----> "+JSON.stringify( user));
        HTTP.post( tickerAPI+"/protected/logout/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+user.wsID)
                    .then( response => {
                         console.log("----> "+JSON.stringify( response.data));
                     this.getUsers();  
                    }).catch(e => {
                    	showError( that.$toast, "logout: " + e.response.data, 'Error', { position: "topRight" });
                });
      },
      logoutAll(user)
      {
        console.log("logout ----> "+JSON.stringify( user));
        HTTP.post( tickerAPI+"/protected/logout/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+user.userId+"/"+myWSID)
                    .then( response => {
                    console.log("----> "+JSON.stringify( response.data));
                     this.getUsers();  
                    }).catch(e => {
                        console.log("ERR----> "+JSON.stringify( e));
                });
      },
      getServer()
      {
    	let that = this;
    	this.tenantName = sessionStorage.tenant;
        HTTP.post( serviceAPI+"/getNode/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
                    .then( response => {
                    	//alert( JSON.stringify(response.data))
                   		this.$set( this, "node", response.data);
                   		
                    }).catch(e => {
                    	showError( that.$toast, "getServer() > " + e.response.data);
                });
      },
      logoutEach()
      {
        HTTP.post( tickerAPI+"/protected/logoutEach/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+myWSID)
                    .then( response => {
                    console.log("----> "+JSON.stringify( response.data));
                     this.getUsers();  
                    }).catch(e => {
                        console.log("ERR----> "+JSON.stringify( e));
                });
      }
  }, 
  created()
  {
    this.getServer();
    this.getUsers();
    initAppMode();
  }
};
</script>
<style lang="scss" scoped>
th div { padding: 5pt; width: 100%; height: 100%; border-bottom: 2pt solid grey; }
th div:hover { 
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    //border: 1pt outset grey;
    cursor: pointer;
    background-color: #e8f0e8; }
th { padding: 0pt; }
tr[marked=true] { 
    //text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
	font-weight: bold; }
.withPaddingL { padding-left: 20pt;  padding-right: 5pt; }
.withPaddingR { padding-right: 20pt;  padding-left: 5pt; }
</style>